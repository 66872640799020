import { Dayjs, isDayjs } from 'dayjs';
import { USER_ID_COOKIE_KEY } from './constants';
import heic2any from "heic2any";

export function isValidURL(url: string): boolean {
  const urlPattern = new RegExp(
    '^(https?:\\/\\/)' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$',
    'i',
  );

  return !!urlPattern.test(url);
}

// Calculates the difference between date2 and date1
// Coverts that difference into a string with the smallest unit of time
// e.g. 10m, 2h, 3d, >30d
export const smallDateDiff = (date1: Date | Dayjs, date2: Date | Dayjs = new Date()) => {

  const date1AsDate = isDayjs(date1) ? date1.toDate() : date1;
  const date2AsDate = isDayjs(date2) ? date2.toDate() : date2;

  let result = '';


  const diffTime = (date2AsDate.getTime() - date1AsDate.getTime()) / 60000;

  // negative diffTime
  if (diffTime < 0) {
    result = '--';
    // 60 minutes
  } else if (diffTime < 60) {
    result = `${Math.floor(diffTime)}m`;
    // 24 hours
  } else if (diffTime < 1440) {
    result = `${Math.floor(diffTime / 60)}h`;
    // 120 days
  } else if (diffTime <= 172800) {
    result = `${Math.floor(diffTime / 1440)}d`;
  } else {
    result = `>120d`;
  }

  return result;
};

export const getCookieValue = (name: string) => {
  const cookies = document.cookie.split('; ');
  const cookie = cookies.find((c) => c.startsWith(`${name}=`));
  if (cookie) {
    return cookie.split('=')[1];
  }
  return null;
};

export const getUserIdFromCookie = () => {
  let raw = getCookieValue(USER_ID_COOKIE_KEY);
  if (raw) {
    const userId = raw.split('.')[0];
    return parseInt(userId);
  }
  return null;
};

export const capitalizeString = (str: string) => {
  return str.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase());
};

export const shortenFilename = (name: string, maxLength: number) => {
  if (name.length <= maxLength) {
    return name;
  }

  const dotIndex = name.lastIndexOf('.');
  const extension = dotIndex !== -1 ? name.substring(dotIndex) : '';
  const baseName = dotIndex !== -1 ? name.substring(0, dotIndex) : name;

  const shortenedBaseName = baseName.substring(0, maxLength - 3 - extension.length);

  return `${shortenedBaseName}...${extension}`;
};

export const convertHeicToJpeg = async (fileUrl: string): Promise<string | undefined> => {
  try {
    // Fetch the file as a Blob
    const response = await fetch(fileUrl);
    const blob = await response.blob();

    // Convert the HEIC file to JPEG
    const convertedBlob = await heic2any({ blob, toType: "image/jpeg" });

    // Return a URL for the converted image
    return URL.createObjectURL(convertedBlob as Blob);
  } catch (error) {
    console.error("Failed to convert HEIC file:", error);
    return undefined;
  }
};
