import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from 'react';
import { isLoanOpsUwPlusUser } from '../../../../helpers/auth';
import { UWToolValuesHistoryAction, UwUidFieldTypes } from '../../../../helpers/constants';
import { niceDate } from '../../../../helpers/converters';
import { getUserIdFromCookie } from '../../../../helpers/helpers';

const userId = getUserIdFromCookie();
const canEdit = isLoanOpsUwPlusUser();

export const UWToolValuesEditor = () => {
  const [expanded, setExpanded] = useState(false);
  const [error, setError] = useState<string | null>(null)
  const [uids, setUids] = useState<any[]>([])
  const [uid, setUid] = useState("")
  const [uidSelection, setUidSelection] = useState("")
  const [uidInputValue, setUidInputValue] = useState("")
  const [applicationID, setApplicationID] = useState("")
  const [uidRows, setUidRows] = useState<any[]>([])
  const [showDeleted, setShowDeleted] = useState(false)
  const [openRows, setOpenRows] = useState<Record<number, boolean>>({});
  const [uidFields, setUidFields] = useState<any[]>([])
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [editMode, setEditMode] = useState<'create' | 'edit'>('create');
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const [editUid, setEditUid] = useState<string | null>(null);
  const [editUidType, setEditUidType] = useState<string | null>(null);
  const [editUidInputValue, setEditUidInputValue] = useState<string | null>(null);
  const [editString, setEditString] = useState<string | null>(null);
  const [editNumber, setEditNumber] = useState<number | null>(null);
  const [editMixed, setEditMixed] = useState<string | null>(null);
  const [editError, setEditError] = useState<string | null>(null);
  const [editComment, setEditComment] = useState<string | null>(null);
  const [deleteComment, setDeleteComment] = useState<string | null>(null);
  const [deleteError, setDeleteError] = useState<string | null>(null);

  useEffect(() => {
    setError('');
    axios
      .get('/api/v1/data-tool/uw-uid-field')
      .then((response) => {
        // dict with key as uid and field name, used in autocomplete fields
        let UIDs: any = {}
        for (const item of response.data) {
          UIDs[`${item.uid} - ${item.field_name}`] = item
        }
        setUids(UIDs);
        // dict with key as uid, used to get field type from just uid
        let UIDFields: any = {}
        for (const item of response.data) {
          UIDFields[item.uid] = item
        }
        setUidFields(UIDFields);
      })
      .catch((_error) => setError('Error fetching application IDs'))
      .finally(() => {
      });

  }, []);

  const handleExpandClick = () => {
    setExpanded((prevExpanded) => !prevExpanded);
  };

  const isValidApplicationID = (appID: string) => {
    const regex = /^A-\d{7}$/;
    return regex.test(appID || '');
  }

  const getUIDs = (appID: string) => {
    setError('');
    if (!isValidApplicationID(appID)) {
      return;
    }
    axios
      .get(`/api/v1/data-tool/uw-tool-values/application-id/${appID}`)
      .then((response) => {
        if (response.data.length > 0) {
          setUidRows(response.data);
        } else {
          setUidRows([]);
          setError(`No data found for application ID: ${appID}`);
        }
        setUidSelection('');
        setUidInputValue('');
        setUid('');
      })
      .catch((_error) => setError(`Error fetching data for application ID: ${appID}`));
  }

  const toggleRow = (uid: string) => {
    setOpenRows((prev) => ({ ...prev, [uid]: !prev[uid] }));
  };

  const handleApplicationIDChange = (appID: string) => {
    setApplicationID(appID);

    if (isValidApplicationID(appID)) {
      getUIDs(appID);
      return;
    }
  }

  const getUidToolTip = (uid: string): JSX.Element => {
    const data = uidFields[uid]
    if (!data) {
      return <>No Data</>
    }
    return (
      <>
        Sheet: {data.sheet}<br />
        Category: {data.category}<br />
        Field Name: {data.field_name}<br />
        Unit: {data.unit}<br />
        Type: {data.type}
      </>
    )
  }

  const handleEditOpen = (item) => {
    if (item) {
      setEditMode('edit');
      setSelectedItem(item);
      setEditUid(item.uid);
      setEditUidType(uidFields[item.uid]?.type || null);
      setEditString(item.string_value || '');
      setEditNumber(item.number_value !== null ? item.number_value : null);
      setEditMixed(item.mixed_value !== null ? JSON.stringify(item.mixed_value, null, 2) : null);
      setEditComment('');
    } else {
      setEditMode('create');
      setSelectedItem(null);
      setEditUid(null);
      setEditUidType(null);
      setEditUidInputValue('');
      setEditString(null);
      setEditNumber(null);
      setEditMixed(null);
      setEditComment(null);
    }
    setEditOpen(true);
  };

  const handleEditUidChange = (newValue: string) => {
    setEditUid(newValue);
    setEditUidType(uids[newValue]?.type || null);
    setEditError(null);
    setEditComment(null);
    setEditString(null);
    setEditNumber(null);
    setEditMixed(null);
  }

  const handleEditClose = () => {
    setEditOpen(false);
    setEditError(null);
    setSelectedItem(null);
    setEditUid(null);
    setEditComment(null);
    setEditString(null);
    setEditNumber(null);
    setEditMixed(null);
  };

  const handleEditSave = async () => {
    setEditError(null);

    if (!editUid || !editUidType) {
      setEditError('You must select a valid UID');
      return;
    }

    let saveString = editString;
    if (editUidType === UwUidFieldTypes.STRING) {
      if (!editString) {
        setEditError('You must provide a string value');
        return;
      }
    } else {
      if (saveString === '') {
        saveString = null;
      }
    }

    if (editUidType === UwUidFieldTypes.NUMBER && !editNumber && editNumber !== 0) {
      setEditError('You must provide a number value');
      return;
    }
    if (editUidType === UwUidFieldTypes.JSON && !editMixed) {
      setEditError('You must provide a mixed value');
      return;
    }

    if (!editComment) {
      setEditError('You must provide a comment');
      return;
    }

    // parse mixed value
    let jsonMixed = null;
    if (editMixed !== null && editMixed.trim() !== '') {
      try {
        // Remove \n characters before parsing the JSON
        const cleanedEditMixed = editMixed.replace(/\n/g, '');
        jsonMixed = JSON.parse(cleanedEditMixed);
      } catch (error) {
        console.error('Invalid JSON format:', error);
      }
    }

    if (editMode === 'create') {
      const uid = uids[editUid].uid;

      for (const row of uidRows) {
        if (row.uid === uid) {
          setEditError('UID already exists');
          return;
        }
      }

      await axios
        .post(`/api/v1/data-tool/uw-tool-values/${applicationID}/${uid}`, {
          string_value: saveString,
          number_value: editNumber,
          mixed_value: jsonMixed,
        })
        .catch((_error) => setError(`Error creating record`))

      await createHistoryRecord(UWToolValuesHistoryAction.CREATE, uid, saveString, editNumber, jsonMixed, editComment);
    } else {
      // we have to create the history record first because we might need the existing values of the record to create an initial history record
      await createHistoryRecord(UWToolValuesHistoryAction.UPDATE, editUid, saveString, editNumber, jsonMixed, editComment);

      await axios
        .patch(`/api/v1/data-tool/uw-tool-values/${selectedItem.application_id}/${selectedItem.uid}`, {
          string_value: saveString,
          number_value: editNumber,
          mixed_value: jsonMixed,
        })
        .catch((_error) => setError(`Error updating record`))
    }
    getUIDs(applicationID);
    handleEditClose();
  };

  const createHistoryRecord = async (action: UWToolValuesHistoryAction, uid: string, string_value: string | null, number_value: number | null, mixed_value: any | null, comment: string) => {
    await axios
      .post(`/api/v1/data-tool/uw-tool-values-history/${applicationID}/${uid}`, {
        action: action,
        string_value: string_value,
        number_value: number_value,
        mixed_value: mixed_value,
        comment: comment,
        created_by: userId,
      })
      .catch((_error) => setError(`Error creating history record`));
  }

  const handleDeleteOpen = (item) => {
    setSelectedItem(item);
    setEditComment('');
    setDeleteOpen(true);
  };

  const handleDeleteClose = () => {
    setDeleteOpen(false);
    setSelectedItem(null);
    setDeleteComment(null);
  };

  const handleDeleteConfirm = async () => {
    setDeleteError(null);
    if (!selectedItem) {
      return;
    }
    if (!deleteComment) {
      setDeleteError('You must provide a comment');
      return;
    }
    await axios
      .patch(`/api/v1/data-tool/uw-tool-values/toggle-deleted/${selectedItem.uid}/${selectedItem.application_id}/${selectedItem.is_deleted}`)
      .catch((_error) => setError(`Error ${selectedItem.is_deleted ? 'restoring' : 'deleting'} item`))
      .finally(() => {
        getUIDs(applicationID);
      });
    await createHistoryRecord(selectedItem.is_deleted ? UWToolValuesHistoryAction.RESTORE : UWToolValuesHistoryAction.DELETE, selectedItem.uid, selectedItem.string_value, selectedItem.number_value, selectedItem.mixed_value, deleteComment);

    handleDeleteClose();
  };

  return (
    <Card sx={{ margin: 'auto', mt: 1, width: '100%' }}>
      <CardHeader
        title="UW Tool Values Editor"
        onClick={handleExpandClick}
        action={
          <IconButton
            onClick={handleExpandClick}
            sx={{
              transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)',
              transition: 'transform 0.3s',
            }}
          >
            <ExpandMoreIcon />
          </IconButton>
        }
      />
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Grid container spacing={2} sx={{ mb: 2, ml: 1 }}>
            <Typography sx={{ mt: 2 }}>
              Application ID:
            </Typography>
            <TextField
              value={applicationID}
              onChange={(e) => handleApplicationIDChange(e.target.value)}
              sx={{ width: '10%', mb: 1, ml: 1 }}
            />
            <Autocomplete
              sx={{ width: '25%', ml: 1 }}
              options={Object.keys(uids)}
              value={uidSelection}
              onChange={(_event, newValue) => {
                if (newValue) {
                  setUidSelection(newValue);
                  setUid(uids[newValue].uid);
                } else {
                  setUidSelection('');
                  setUid('');
                  setUidInputValue('');
                }
              }}
              inputValue={uidInputValue}
              onInputChange={(_event, newInputValue) => {
                setUidInputValue(newInputValue);
              }}
              filterOptions={(options, { inputValue }) =>
                options.filter((option) => option.toLowerCase().includes(inputValue.toLowerCase()))
              }
              isOptionEqualToValue={(option, value) => option === value || value === ''}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="UID"
                />
              )}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={showDeleted}
                  onChange={(event) => setShowDeleted(event.target.checked)}
                  name="showDeletedCheckbox"
                  color="primary"
                />
              }
              label="Show Deleted"
              sx={{ ml: 1 }}
            />
            {canEdit && (
              <Button variant="contained" color="success" sx={{ ml: 1, height: '36px', mt: 1 }} disabled={!isValidApplicationID(applicationID)} onClick={() => handleEditOpen(null)}>Create</Button>
            )}
          </Grid>
          <Box>
            {error && (
              <Typography color="error" sx={{ mt: 2 }}>
                {error}
              </Typography>
            )}
            <TableContainer component={Paper} sx={{ maxHeight: 700 }}>
              <Table stickyHeader sx={{ tableLayout: 'fixed', width: '100%' }}>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ textAlign: 'center' }}>
                      <strong>UID</strong>
                    </TableCell>
                    <TableCell
                      sx={{
                        width: '300px',
                        textAlign: 'center',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      <strong>Field Name</strong>
                    </TableCell>
                    <TableCell sx={{ textAlign: 'center' }}>
                      <strong>Type</strong>
                    </TableCell>
                    <TableCell sx={{ textAlign: 'center' }}>
                      <strong>Value</strong>
                    </TableCell>
                    <TableCell sx={{ textAlign: 'center' }}>
                      <strong>Created At</strong>
                    </TableCell>
                    <TableCell sx={{ textAlign: 'center' }}>
                      <strong>Updated At</strong>
                    </TableCell>
                    {showDeleted && (
                      <TableCell sx={{ textAlign: 'center' }}>
                        <strong>Deleted</strong>
                      </TableCell>
                    )}
                    {canEdit && (
                      <>
                        <TableCell />
                        <TableCell />
                      </>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {uidRows
                    .filter((item) => !(item.is_deleted && !showDeleted))
                    .filter((item) => !uid || item.uid === uid)
                    .map((item) => (
                      <React.Fragment key={item.uid}>
                        <TableRow
                          key={item.uid}
                          hover
                          style={{ cursor: 'pointer', height: '36px' }} // Optional: Adjust row height
                          sx={{
                            '& td, & th': {
                              padding: '4px', // Adjust cell padding
                            },
                          }}
                        >
                          <TableCell sx={{ textAlign: 'center' }}>
                            <Tooltip title={getUidToolTip(item.uid)} arrow>
                              <span>{item.uid}</span>
                            </Tooltip>
                          </TableCell>
                          <TableCell
                            sx={{
                              maxWidth: '300px',
                              // overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'wrap',
                            }}
                          >
                            {uidFields[item.uid] ? uidFields[item.uid].field_name : 'UNKNOWN'}
                          </TableCell>
                          <TableCell sx={{ textAlign: 'center' }}>
                            {uidFields[item.uid] ? uidFields[item.uid].type : 'UNKNOWN'}
                          </TableCell>
                          {uidFields[item.uid]?.type === UwUidFieldTypes.STRING &&
                            <TableCell>{item.string_value}</TableCell>
                          }
                          {uidFields[item.uid]?.type === UwUidFieldTypes.NUMBER &&
                            <TableCell sx={{ textAlign: 'right' }}>{item.number_value}</TableCell>
                          }
                          {uidFields[item.uid]?.type === UwUidFieldTypes.JSON &&
                            <TableCell
                              onClick={() => item.mixed_value !== null ? toggleRow(item.uid) : null}
                              sx={{ cursor: 'pointer', userSelect: 'none', textAlign: openRows[item.uid] ? 'left' : 'center', }} // Add pointer cursor for better UX
                            >
                              <Collapse in={openRows[item.uid]} timeout={0} unmountOnExit>
                                <pre style={{ margin: 0 }}>
                                  {JSON.stringify(item.mixed_value, null, 2)}
                                </pre>
                              </Collapse>
                              {item.mixed_value && !openRows[item.uid] && 'Click to expand'}
                            </TableCell>
                          }
                          <Tooltip title={niceDate(item.created_at, true)} arrow>
                            <TableCell sx={{ textAlign: 'center' }}>{niceDate(item.created_at)}</TableCell>
                          </Tooltip>
                          <Tooltip title={niceDate(item.updated_at, true)} arrow>
                            <TableCell sx={{ textAlign: 'center' }}>{niceDate(item.updated_at)}</TableCell>
                          </Tooltip>
                          {showDeleted && (
                            <TableCell sx={{ textAlign: 'center' }}>{item.is_deleted ? 'Yes' : ''}</TableCell>
                          )}
                          {canEdit && (
                            <>
                              <TableCell sx={{ textAlign: 'center' }}>
                                <Button variant="contained" color="info" sx={{ height: '26px' }} onClick={() => handleEditOpen(item)}>Edit</Button>
                              </TableCell>
                              <TableCell sx={{ textAlign: 'center' }}>
                                <Button variant="contained" color={item.is_deleted ? "warning" : "error"} sx={{ height: '26px' }} onClick={() => handleDeleteOpen(item)}>{item.is_deleted ? 'Restore' : 'Delete'}</Button>
                              </TableCell>
                            </>
                          )}
                        </TableRow>
                      </React.Fragment>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>

          {/* edit dialog */}
          <Dialog open={editOpen} onClose={handleEditClose} fullWidth maxWidth="lg">
            <DialogTitle>
              {`${editMode === 'create' ? 'Create' : 'Edit'} Mode`}
              {editError && <Typography color="error">{editError}</Typography>}
            </DialogTitle>
            <DialogContent>
              <Typography sx={{ mb: 1 }}>
                {`Application ID: ${applicationID}`}
              </Typography>
              {editMode === 'edit' &&
                <Typography>
                  {`UID: ${editUid}`}
                </Typography>
              }
              {editMode === 'create' &&
                <Autocomplete
                  sx={{ width: '100%' }}
                  options={Object.keys(uids)}
                  value={editUid}
                  onChange={(_event, newValue) => {
                    if (newValue) {
                      handleEditUidChange(newValue);
                    } else {
                      setEditUid('');
                    }
                  }}
                  inputValue={editUidInputValue || ''}
                  onInputChange={(_event, newInputValue) => {
                    setEditUidInputValue(newInputValue);
                  }}
                  filterOptions={(options, { inputValue }) =>
                    options.filter((option) => option.toLowerCase().includes(inputValue.toLowerCase()))
                  }
                  isOptionEqualToValue={(option, value) => option === value || value === ''}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="UID"
                    />
                  )}
                />
              }
              <Typography sx={{ mt: 1 }}>
                Comment (required):
              </Typography>
              <TextField
                value={editComment || ''}
                onChange={(e) => setEditComment(e.target.value)}
                sx={{ width: '100%', mb: 1 }}
                multiline
                rows={4}
              />
              {editUidType === UwUidFieldTypes.STRING &&
                <>
                  <Typography>
                    String Value:
                  </Typography>
                  <TextField
                    value={editString || ''}
                    onChange={(e) => setEditString(e.target.value)}
                    sx={{ width: '100%', mb: 1 }}
                  />
                </>
              }
              {editUidType === UwUidFieldTypes.NUMBER &&
                <>
                  <Typography>
                    Number Value:
                  </Typography>
                  <TextField
                    value={editNumber}
                    type="number"
                    sx={{ width: '100%', mb: 1 }}
                    onChange={(e) => setEditNumber(e.target.value === '' ? null : Number(e.target.value))}
                  />
                </>
              }
              {editUidType === UwUidFieldTypes.JSON &&
                <>
                  <Typography>
                    Mixed Value:
                  </Typography>
                  <TextField
                    value={editMixed !== 'null' && editMixed !== null ? editMixed : ''}
                    multiline
                    sx={{
                      width: '100%',
                      height: '400px',
                      overflow: 'auto',
                      '& textarea': {
                        whiteSpace: 'pre-wrap', // Ensures text wraps and preserves whitespace
                        wordWrap: 'break-word', // Ensures long words wrap properly
                      },
                      mb: 1,
                    }}
                    onChange={(e) => setEditMixed(e.target.value)}
                  />
                </>
              }
            </DialogContent>
            <DialogActions>
              <Button onClick={handleEditClose} color="primary">
                Cancel
              </Button>
              <Button onClick={handleEditSave} color="secondary" autoFocus>
                {editMode === 'create' ? 'Create' : 'Update'}
              </Button>
            </DialogActions>
          </Dialog>

          {/* delete dialog */}
          <Dialog open={deleteOpen} onClose={handleDeleteClose}>
            <DialogTitle>
              {selectedItem?.is_deleted ? 'Restore Item' : 'Delete Item'}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                Are you sure you want to {selectedItem?.is_deleted ? 'restore' : 'delete'} this item?
              </DialogContentText>
              <Typography>
                Comment (required):
              </Typography>
              <TextField
                value={deleteComment}
                onChange={(e) => setDeleteComment(e.target.value)}
                sx={{ width: '100%', mb: 1 }}
                multiline
                rows={4}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDeleteClose} color="primary">
                No
              </Button>
              <Button onClick={handleDeleteConfirm} color="secondary" disabled={!deleteComment} autoFocus>
                Yes
              </Button>
            </DialogActions>
          </Dialog>

          {deleteError && (
            <Typography color="error" sx={{ mt: 2 }}>
              {deleteError}
            </Typography>
          )}
        </CardContent>
      </Collapse>
    </Card>
  )
};