import { Paper, Typography } from '@mui/material';
import React from 'react';
import { InstallerScorecardUpload } from '../../components/Underwriting/InstallerScorecardUpload';
import { UnderwritingTool } from '../../components/Underwriting/UnderwritingTool';
import { isLoanOpsUwPlusUser } from '../../helpers/auth';
import { UWToolValuesEditor } from './tools/UWTool/UWToolValuesEditor';

export const UwDataTools = () => {
  return (
    <Paper
      sx={{ p: '1rem', display: 'flex', flexDirection: 'column', gap: '2rem' }}
    >
      <Typography variant="h5">Underwriting Data Tools</Typography>
      <UnderwritingTool />
      <InstallerScorecardUpload />
      {isLoanOpsUwPlusUser() && <UWToolValuesEditor />}
    </Paper>
  );
};
