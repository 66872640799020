import { isGroupsUser, UserGroup } from './auth';

// user groups that are allowed to access the Loans tab
export const canAccessLoanTab = () => {
    return isGroupsUser([UserGroup.LOAN_OPS, UserGroup.UW, UserGroup.LOAN_OPS_UW, UserGroup.LOAN_OPS_UW_PLUS, UserGroup.BIZ_DEV, UserGroup.FIN_OPS, UserGroup.BORROWER_SUCCESS]);
};

// user groups that are allowed to use SFTP
export const canAccessSFTPTab = () => {
    return isGroupsUser([
        UserGroup.LOAN_OPS,
        UserGroup.FIN_OPS,
        UserGroup.LOAN_OPS_UW,
        UserGroup.LOAN_OPS_UW_PLUS,
    ]);
};

// user groups that are allowed to access the Funded Projects tab
export const canAccessFundedProjectsTab = () => {
    return isGroupsUser([UserGroup.BORROWER_SUCCESS, UserGroup.UW, UserGroup.FIN_OPS]);
};

// user groups that are allowed to access the Data Uploaders tab
export const canAccessDataUploadersTab = () => {
    return isGroupsUser([UserGroup.UW, UserGroup.LOAN_OPS_UW, UserGroup.LOAN_OPS_UW_PLUS]);
};

// user groups that are allowed to access the Tools tab
export const canAccessToolsTab = () => {
    return isGroupsUser([UserGroup.UW, UserGroup.BORROWER_SUCCESS, UserGroup.BIZ_DEV]);
};

// user groups that are allowed to access the Tools -> Announcements tab
export const canAccessToolsAnnouncementsTab = () => {
    return isGroupsUser([UserGroup.BIZ_DEV]);
};

// user groups that are allowed to read and write files
export const canRWFiles = () => {
    return isGroupsUser([UserGroup.LOAN_OPS, UserGroup.LOAN_OPS_UW, UserGroup.LOAN_OPS_UW_PLUS, UserGroup.UW, UserGroup.BORROWER_SUCCESS]);
};

// user groups that are allowed to view the Loans search bar in the header
export const canViewHeaderLoansSearchBar = () => {
    return isGroupsUser([UserGroup.LOAN_OPS, UserGroup.UW, UserGroup.LOAN_OPS_UW, UserGroup.LOAN_OPS_UW_PLUS, UserGroup.BIZ_DEV, UserGroup.BORROWER_SUCCESS]);
};

