export const CO_UTILITES = ['Xcel Energy', 'Other'];

export const IL_UTILITES = [
  'Ameren Illinois',
  'Commonwealth Edison',
  'MidAmerican Energy',
  'Mt. Carmel',
  'Other (MISO)',
  'Other (PJM)',
];

export const MA_TERRITORIES = [
  'Eversource East',
  'Eversource West',
  'National Grid (Massachusetts Electric)',
  'National Grid (Nantucket)',
  'Unitil',
  'Other',
];

// TODO: refactor into Railway call?
export function getRecInfoChoicesByState(state: string) {
  let choices: string[] = [];
  switch (state) {
    case 'Colorado':
      choices = CO_UTILITES;
      break;
    case 'Illinois':
      choices = IL_UTILITES;
      break;
    case 'Massachusetts':
      choices = MA_TERRITORIES;
      break;
  }
  return choices;
}

export const UNITED_STATES_FOR_DROPDOWN = [
  { label: 'Alabama', value: 'Alabama', abbreviation: 'AL' },
  { label: 'Alaska', value: 'Alaska', abbreviation: 'AK' },
  { label: 'Arizona', value: 'Arizona', abbreviation: 'AZ' },
  { label: 'Arkansas', value: 'Arkansas', abbreviation: 'AR' },
  { label: 'California', value: 'California', abbreviation: 'CA' },
  { label: 'Colorado', value: 'Colorado', abbreviation: 'CO' },
  { label: 'Connecticut', value: 'Connecticut', abbreviation: 'CT' },
  { label: 'Delaware', value: 'Delaware', abbreviation: 'DE' },
  {
    label: 'District of Columbia (Washington, D.C.)',
    value: 'Washington, D.C.',
    abbreviation: 'DC',
  },
  { label: 'Florida', value: 'Florida', abbreviation: 'FL' },
  { label: 'Georgia', value: 'Georgia', abbreviation: 'GA' },
  { label: 'Hawaii', value: 'Hawaii', abbreviation: 'HI' },
  { label: 'Idaho', value: 'Idaho', abbreviation: 'ID' },
  { label: 'Illinois', value: 'Illinois', abbreviation: 'IL' },
  { label: 'Indiana', value: 'Indiana', abbreviation: 'IN' },
  { label: 'Iowa', value: 'Iowa', abbreviation: 'IA' },
  { label: 'Kansas', value: 'Kansas', abbreviation: 'KS' },
  { label: 'Kentucky', value: 'Kentucky', abbreviation: 'KY' },
  { label: 'Louisiana', value: 'Louisiana', abbreviation: 'LA' },
  { label: 'Maine', value: 'Maine', abbreviation: 'ME' },
  { label: 'Maryland', value: 'Maryland', abbreviation: 'MD' },
  { label: 'Massachusetts', value: 'Massachusetts', abbreviation: 'MA' },
  { label: 'Michigan', value: 'Michigan', abbreviation: 'MI' },
  { label: 'Minnesota', value: 'Minnesota', abbreviation: 'MN' },
  { label: 'Mississippi', value: 'Mississippi', abbreviation: 'MS' },
  { label: 'Missouri', value: 'Missouri', abbreviation: 'MO' },
  { label: 'Montana', value: 'Montana', abbreviation: 'MT' },
  { label: 'Nebraska', value: 'Nebraska', abbreviation: 'NE' },
  { label: 'Nevada', value: 'Nevada', abbreviation: 'NV' },
  { label: 'New Hampshire', value: 'New Hampshire', abbreviation: 'NH' },
  { label: 'New Jersey', value: 'New Jersey', abbreviation: 'NJ' },
  { label: 'New Mexico', value: 'New Mexico', abbreviation: 'NM' },
  { label: 'New York', value: 'New York', abbreviation: 'NY' },
  { label: 'North Carolina', value: 'North Carolina', abbreviation: 'NC' },
  { label: 'North Dakota', value: 'North Dakota', abbreviation: 'ND' },
  { label: 'Ohio', value: 'Ohio', abbreviation: 'OH' },
  { label: 'Oklahoma', value: 'Oklahoma', abbreviation: 'OK' },
  { label: 'Oregon', value: 'Oregon', abbreviation: 'OR' },
  { label: 'Pennsylvania', value: 'Pennsylvania', abbreviation: 'PA' },
  { label: 'Rhode Island', value: 'Rhode Island', abbreviation: 'RI' },
  { label: 'South Carolina', value: 'South Carolina', abbreviation: 'SC' },
  { label: 'South Dakota', value: 'South Dakota', abbreviation: 'SD' },
  { label: 'Tennessee', value: 'Tennessee', abbreviation: 'TN' },
  { label: 'Texas', value: 'Texas', abbreviation: 'TX' },
  { label: 'Utah', value: 'Utah', abbreviation: 'UT' },
  { label: 'Vermont', value: 'Vermont', abbreviation: 'VT' },
  { label: 'Virginia', value: 'Virginia', abbreviation: 'VA' },
  { label: 'Washington', value: 'Washington', abbreviation: 'WA' },
  { label: 'West Virginia', value: 'West Virginia', abbreviation: 'WV' },
  { label: 'Wisconsin', value: 'Wisconsin', abbreviation: 'WI' },
  { label: 'Wyoming', value: 'Wyoming', abbreviation: 'WY' },
];

export const UserGroups = {
  INSTALLER: 'INSTALLER',
  CONSULTANT: 'CONSULTANT',
  INSTALLER_AND_CONSULTANT: 'INSTALLER_AND_CONSULTANT',
  BORROWER: 'BORROWER',
  ALL: 'ALL',
};

// announcements
export const LIVE_ANNOUNCEMENTS_LIMIT = 4;
export const ANNOUNCEMENT_MAX_BODY_LENGTH = 200;

// TODO: merge with UserGroups when UserGroups is expanded to contain all of these values
export const RequiredDocumentUserGroups = {
  INSTALLER: 'INSTALLER',
  CONSULTANT: 'CONSULTANT',
  BORROWER: 'BORROWER',
  INSTALLER_AND_CONSULTANT: 'INSTALLER_AND_CONSULTANT',
  CONSULTANT_AND_BORROWER: 'CONSULTANT_AND_BORROWER',
  BORROWER_AND_INSTALLER: 'BORROWER_AND_INSTALLER',
  ALL: 'ALL',
};

export const RequiredFor = {
  BORROWER_APP_SUBMISSION: 'BORROWER_APP_SUBMISSION',
  UNDERWRITING_APPROVAL: 'UNDERWRITING_APPROVAL',
  BORROWER_SIGNING: 'BORROWER_SIGNING',
  PROJECT_START: 'PROJECT_START',
  NOTICE_TO_PROCEED: 'NOTICE_TO_PROCEED',
  EQUIPMENT_ORDER: 'EQUIPMENT_ORDER',
  RACKING_COMPLETION: 'RACKING_COMPLETION',
  MECHANICAL_COMPLETION: 'MECHANICAL_COMPLETION',
  SUBSTANTIAL_COMPLETION: 'SUBSTANTIAL_COMPLETION',
  FINAL_COMPLETION: 'FINAL_COMPLETION',
};

export const RequiredDocumentApprovalStatus = {
  AWAITING_UPLOAD: 'AWAITING_UPLOAD',
  VERIFIED: 'VERIFIED',
  NEEDS_REVIEW: 'NEEDS_REVIEW',
  ISSUES_IDENTIFIED: 'ISSUES_IDENTIFIED',
  MISSING: 'MISSING',
};

export const UwUidFieldTypes = {
  STRING: 'String',
  NUMBER: 'Number',
  JSON: 'JSON',
};

export enum UWToolValuesHistoryAction {
  CREATE = 'Create',
  UPDATE = 'Update',
  DELETE = 'Delete',
  RESTORE = 'Restore',
}

export const RequiredDocumentSubType = {
  PG: 'PG',
  CG: 'CG',
  BORROWER: 'BORROWER',
};

export const USER_COOKIE_KEY = 'user';
export const USER_ID_COOKIE_KEY = 'user_id';
export const USER_ROLE_COOKIE_KEY = 'user_role';
export const USER_GROUP_COOKIE_KEY = 'user_group';
export const USER_NAME_COOKIE_KEY = 'name';

export const MONTHS = [
  '',
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const ENVS = [
  { name: 'Staging', url: 'https://api.rosefield.xyz/v1/', secretId: 'userTypesStaging' },
  { name: 'Production', url: 'https://api.sunstonecredit.com/v1/', secretId: 'userTypesProd' },
]

export const ENDPOINTS = {
  CALCULATE_PAYMENTS: { name: 'Calculate Payments', endpoint: 'calculate-payments', method: 'POST' },
  USER_STATUS: { name: 'Verify User Status', endpoint: 'user-status', method: 'POST' },
}

export const STATUS_CODES = {
  200: 'OK',
  201: 'Created',
  202: 'Accepted',
  203: 'Non-Authoritative Information',
  204: 'No Content',
  205: 'Reset Content',
  206: 'Partial Content',
  300: 'Multiple Choices',
  301: 'Moved Permanently',
  302: 'Found',
  303: 'See Other',
  304: 'Not Modified',
  305: 'Use Proxy',
  306: 'Unused',
  307: 'Temporary Redirect',
  400: 'Bad Request',
  401: 'Unauthorized',
  402: 'Payment Required',
  403: 'Forbidden',
  404: 'Not Found',
  405: 'Method Not Allowed',
  406: 'Not Acceptable',
  407: 'Proxy Authentication Required',
  408: 'Request Timeout',
  409: 'Conflict',
  410: 'Gone',
  411: 'Length Required',
  412: 'Precondition Required',
  413: 'Request Entry Too Large',
  414: 'Request-URI Too Long',
  415: 'Unsupported Media Type',
  416: 'Requested Range Not Satisfiable',
  417: 'Expectation Failed',
  418: 'I\'m a teapot',
  429: 'Too Many Requests',
  500: 'Internal Server Error',
  501: 'Not Implemented',
  502: 'Bad Gateway',
  503: 'Service Unavailable',
  504: 'Gateway Timeout',
  505: 'HTTP Version Not Supported',
};

export enum FileUploadSource {
  ROSEFIELD = 'ROSEFIELD',
  RAILWAY = 'RAILWAY',
}

// These need to match the NoteType enum in the backend Prisma enum "los.NoteType"
export enum NoteType {
  External = 'External',
  Internal = 'Internal'
}

export const NonUniqueDocumentTypes = [
  'Utility Bills',
  'Incentive Documents',
  'Additional Document',
  'Offtaker Creditworthiness Proof',
  'Offtaker Utility Bill',
  'Offtaker PPA Lease Agreement',
  'Community Solar Financial Projections',
  'Utility API File',
];
